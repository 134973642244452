import React from 'react';
import { Image } from 'react-bootstrap';

import './ResponsiveImage.scss';

const ResponsiveImage = props => {

    return (
      <div>
        <p className="responsive-image">
          <Image src={props.src} fluid />
        </p>
      </div>
    );
  }
    
  export default ResponsiveImage;