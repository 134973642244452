import { Card, Row, Col, Alert, Badge } from 'react-bootstrap';

const Linksfcvb2122 = (props) => {
    return (
        <div>
            <Alert variant='warning'>
                <h1>Equip <Badge bg="danger">Tècnic</Badge> Temporada 21-22</h1>
                <div style={{textAlign: 'right'}}><a href="/Linksfcvb" class="btn btn-primary btn-lg active" role="button" aria-pressed="true">Temporada actual</a></div>
            </Alert>
            <Card>
                        <Card.Img variant="top" src="img/Tecnic21-22.png" height="auto" width="auto"/>
                        <Card.Header>Equip Tècnic</Card.Header>
                        <Card.Body>
                            <Card.Text>Direcció esportiva: <br/><b>Irene Moncunill</b></Card.Text>
                            <Card.Text>Coordinació Competició: <br/><b>Andrea Vàzquez</b></Card.Text>
                            <Card.Text>Coordinació Formació: <br/><b>Eva Valls</b></Card.Text>
                        </Card.Body>
                    </Card>
            <Alert variant='info'>
                <h1>Us presentem els nostres <Badge bg="danger">Equips</Badge>  Temporada 21-22</h1>
            </Alert>
            <Row xs={1} md={2} lg={3} className="g-4">
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/Junior21-22.png" height="auto" width="auto"/>
                        <Card.Header>Júnior</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                            <Card.Subtitle className="mb-2 text-muted">Primera divisió júnior femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup D <Card.Link href="https://fcvolei.cat/voleibol/1A-DIVISIO-JUNIOR-FEMENINA-2122" target="_blank">Obrir Fase 1</Card.Link> </Card.Text>
                            <Card.Text>Seleccionar Campionat 2 <Card.Link href="https://fcvolei.cat/voleibol/1A-DIVISIO-JUNIOR-FEMENINA-2122" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/JuvenilA21-22.png" height="auto" width="auto" />
                        <Card.Header>Juvenil A</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup E <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-JUVENIL-FEMENINA-2122" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Ascens 3 <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-JUVENIL-FEMENINA-2122" target="_blank">Obrir Fase 2</Card.Link></Card.Text>                           
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/JuvenilB21-22.png" height="auto" width="auto" />
                        <Card.Header>Juvenil B</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup I <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-JUVENIL-FEMENINA-2122" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Classiicació 2 <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-JUVENIL-FEMENINA-2122" target="_blank">Obrir Fase 2</Card.Link></Card.Text>                            
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/CadetA21-22.png" height="auto"  width="auto"/>
                        <Card.Header>Cadet A</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup E <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-CADET-FEMENINA-2122" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Ascens <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-CADET-FEMENINA-2122" target="_blank">Obrir Fase 2</Card.Link></Card.Text>                            
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/CadetB21-22.png" height="auto" width="auto" />
                        <Card.Header>Cadet B</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup I <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-CADET-FEMENINA-2122" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Classiicació 2 <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-CADET-FEMENINA-2122" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/CadetC21-22.png" height="auto"  width="auto"/>
                        <Card.Header>Cadet C</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                            <Card.Subtitle className="mb-2 text-muted">Preferent cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup A <Card.Link href="https://fcvolei.cat/voleibol/PREFERENT-CADET-FEMENI-2122" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Campionat 1 <Card.Link href="https://fcvolei.cat/voleibol/PREFERENT-CADET-FEMENI-2122" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/CadetD21-22.png" height="auto"  width="auto"/>
                        <Card.Header>Cadet D</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                        </Card.Body>
                    </Card>
                </Col>                
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/InfantilVermell21-22.png" height="auto" width="auto" />
                        <Card.Header>Infantil Vermell</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup J <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2122" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Ascens 4 <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2122" target="_blank">Obrir Fase 2</Card.Link></Card.Text> 
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/InfantilBlanc21-22.png" height="auto" width="auto" />
                        <Card.Header>Infantil Blanc</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                            <Card.Subtitle className="mb-2 text-muted">Preferent infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup B <Card.Link href="https://fcvolei.cat/voleibol/PREFERENT-INFANTIL-FEMENI-2122" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Campionat <Card.Link href="https://fcvolei.cat/voleibol/PREFERENT-INFANTIL-FEMENI-2122" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src="img/AleviE21-22.png" height="auto" width="auto" />
                        <Card.Header>Aleví E</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/AleviN21-22.png" height="auto" width="auto" />
                        <Card.Header>Aleví N</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/Veterans21-22.png" height="auto" width="auto" />
                        <Card.Header>Veterans</Card.Header>
                        <Card.Body>
                            {/*<Card.Title>Júnior</Card.Title> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Linksfcvb2122