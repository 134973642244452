import React from 'react';
import { useState } from 'react';
import ResponsiveImage from '../components/ResponsiveImage/ResponsiveImage';
import useFetch from '../components/useFetch';
import BlogList from '../components/Blog/BlogList';
import { Button, Spinner } from 'react-bootstrap';

import InstaFeeds from '../components/Instagram/InstaFeeds'

const Home = () => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const INS_TOKEN = process.env.REACT_APP_INS_TOKEN;
    const {data: blogs, isPending, error} = useFetch(SERVER_URL + 'api/blocs/read.php');
    const [menuBtn, setmenuBtn] = useState(false);
  
    const onMenu1ButtonClick = () => {
        setmenuBtn(true);
    }

    const onMenu2ButtonClick = () => {
        setmenuBtn(false);
    }

    return (
        <div className='content'>
            <ResponsiveImage
            src="img/Club24-25.png"
            width={ 1024 }
            height={ 265 } />
            <br/>

            {error && <div>{error}</div>}
            {isPending && 
              <Button variant="light" disabled>
              <Spinner
                as="span"
                animation="border"
                variant="danger"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span> Carregant...</span>
            </Button>
            }
            <br/>
            {/*
            <div className="d-grid gap-2" >
                <Button variant="danger" size="lg" onClick={onMenu1ButtonClick}>Notícies del Club</Button>
                <Button variant="danger" size="lg" onClick={onMenu2ButtonClick}>Últimes publicacions INSTAGRAM</Button>
            </div>                        
            */}
            {menuBtn && <BlogList blogs={blogs} title="Notícies del Club"/>}

            {!menuBtn &&
                <div>
                    <br /><br />
                    <h1 className="text-center">Últimes publicacions INSTAGRAM </h1>
                    <hr/>
                    <InstaFeeds token={INS_TOKEN} limit={12}/>
                </div>
            }
        </div>
    ) 
}

export default Home