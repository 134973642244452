import { Card, Row, Col, Alert, Badge } from 'react-bootstrap';

const Linksfcvb = (props) => {
    return (
        <div>
            <Alert variant='warning'>
                <h1>Equip <Badge bg="danger">Tècnic</Badge></h1>                
                <div style={{textAlign: 'right'}}>
                    <a href="/Linksfcvb2324" className="btn btn-primary btn-lg active" role="button" aria-pressed="true">Temporada 23-24</a>
                    <span> </span>
                    <a href="/Linksfcvb2223" className="btn btn-primary btn-lg active" role="button" aria-pressed="true">Temporada 22-23</a>
                    <span> </span>
                    <a href="/Linksfcvb2122" className="btn btn-primary btn-lg active" role="button" aria-pressed="true">Temporada 21-22</a>
                    </div>
            </Alert>
            <Card>
                        <Card.Img variant="top" src="img/EquipTecnic2425.png" height="auto" width="auto"/>
                        <Card.Header>Equip Tècnic</Card.Header>
                        <Card.Body>
                            <Card.Text>Direcció esportiva: <br/><b>Nando Figueroa - Berta Altimiras - Clara Clusella</b></Card.Text>
                            <Card.Text>Coordinació Competició: <br/><b>Berta Altimiras - Clara Clusella</b></Card.Text>
                            <Card.Text>Coordinació Formació: <br/><b>Nando Figueroa</b></Card.Text>
                        </Card.Body>
                    </Card>
            <Alert variant='info'>
                <h1>Us presentem els nostres <Badge bg="danger">Equips</Badge></h1>
            </Alert>
            <Row xs={1} md={2} lg={3} className="g-4">
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipSenior2425o.png"  width="auto"/>
                        <Card.Header>Sènior</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora/r: <b>Helen Solaki/Ferran Puigcercós</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió sènior femenina</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar promoció A <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-senior-femenina-2324" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>*/}
                            <Card.Text>Seleccionar grup C <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-senior-femenina-2425" target="_blank">Obrir Fase 1</Card.Link> </Card.Text>                            
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJunior2425o.png"  width="auto"/>
                        <Card.Header>Júnior A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Paula Blanco/Helena Llord</b></Card.Text>
                            <Card.Subtitle className="mb-2 te xt-muted">Segona divisió júnior femenina</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar classificació A <Card.Link href="https://fcvolei.cat/voleibol/2a-junior-femenina-2324" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>*/}
                            <Card.Text>Seleccionar grup D <Card.Link href="https://fcvolei.cat/voleibol/2a-junior-femenina-2425/" target="_blank">Obrir Fase 1</Card.Link> </Card.Text>
                            
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilA2425o.png" width="auto" />
                        <Card.Header>Juvenil A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Berta Altimiras/Neus Granados</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar promoció F <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup L <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>                        
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilB2425o.png"  width="auto" />
                        <Card.Header>Juvenil B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Clara Clusella/Rut Palmerola</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar promoció G <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup K <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilC2425o.png" width="auto" />
                        <Card.Header>Juvenil C</Card.Header>                        
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Laia Bassas</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Juvenil femení preferent</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar classificació B <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup B <Card.Link href="https://fcvolei.cat/voleibol/juvenil-femeni-preferent-2425/" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
    
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilM2425o.png"  width="auto" />
                        <Card.Header>Juvenil M</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenador/a: <b>Manuel Medina/Erola Serrat</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil masculí</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar classificació <Card.Link href="https://fcvolei.cat/voleibol/juvenil-femeni-preferent-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup E <Card.Link href="https://fcvolei.cat/voleibol/2a-juvenil-masculina-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetA2425o.png"   width="auto"/>
                        <Card.Header>Cadet A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Berta AltimirasÇ/Emma Cardona</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                           {/*<Card.Text>Seleccionar promoció F <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup L <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
            
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetB2425o.png"  width="auto" />
                        <Card.Header>Cadet B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Clara Clusella/Rut Palmerola</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar promoció G <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup K <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
        
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetC2425o.png"   width="auto" height="auto"/>
                        <Card.Header>Cadet C</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Laia Bassas</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferent cadet femení</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar classificació B <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup B <Card.Link href="https://fcvolei.cat/voleibol/preferent-cadet-femeni-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
    
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetM2425o.png"   width="auto" height="auto"/>
                        <Card.Header>Cadet M</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenador/a: <b>Manuel Medina/Erola Serrat </b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió cadet masculí</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar classificació OR B <Card.Link href="https://fcvolei.cat/voleibol/preferent-cadet-femeni-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup E <Card.Link href="https://fcvolei.cat/voleibol/2a-cadet-masculina-2425/" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
    
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetT2425o.png" width="auto" />
                        <Card.Header>Cadet Tecnificació</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Anouk Castaño</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>                  
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilA2425o.png"  width="auto" />
                        <Card.Header>Infantil A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Ivet Malagón/Aina Cots</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar classificació OR A <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup R <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilB2425o.png" width="auto" />
                        <Card.Header>Infantil B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Marta Peñalver/Mariona Clusella</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferent infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar classificació A <Card.Link href="https://fcvolei.cat/voleibol/preferent-infantil-femeni-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup A <Card.Link href="https://fcvolei.cat/voleibol/preferent-infantil-femeni-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilM2425o.png" width="auto" />
                        <Card.Header>Infantil M</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Ivet Malagón/Aina Cots</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segoona divisió infantil masculina</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar classificació A <Card.Link href="https://fcvolei.cat/voleibol/preferent-infantil-femeni-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>*/}
                            <Card.Text>Seleccionar grup B <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-masculina-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>  
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilT2425o.png" width="auto" />
                        <Card.Header>Infantil Tecnificació</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Anouk Castaño/Marta Peñalver/Abril Ramisa/Prabal Dadra</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>                              
                <Col>
                    <Card>
                        <Card.Img variant="top" src="img/EquipAleviA2425o.png"  width="auto" />
                        <Card.Header>Aleví A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Anouk Castaño/Blanca Rojas</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Lliga Aleví Mixt 4x4</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar Campionat catalynya 3x3 campionat A <Card.Link href="https://fcvolei.cat/voleibol/campionat-de-catalunya-alevi-3x3-2324/" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>*/}
                            <Card.Text>Seleccionar grup 3 Girona <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-4x4-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            
                            </Card.Footer>                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src="img/EquipAleviB2425o.png"  width="auto" />
                        <Card.Header>Aleví B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Aina Comes/Arianna Solà</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Lliga Aleví Mixt 4x4</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar Campionat 4x4 femení campionat A <Card.Link href="https://fcvolei.cat/voleibol/campionat-de-catalunya-alevi-femeni-2324/" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>*/}
                            <Card.Text>Seleccionar grup 1 Girona <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-4x4-2425" target="_blank">Obrir Fase 1</Card.Link></Card.Text>                            
                            </Card.Footer>                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src="img/EquipAleviT2425o.png"  width="auto" />
                        <Card.Header>Aleví Tecnificació</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Marta Torra</b></Card.Text>
                            {/*<Card.Subtitle className="mb-2 text-muted">Lliga Aleví Mixt</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup 8 <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-2324/" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Mixte Plata <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-2223" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>
                            
                            </Card.Footer>                            */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipBenjamiA2425o.png" width="auto" />
                        <Card.Header>Benjamí A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenador/a: <b>Nando Figueroa/Bruna Ruiz</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipBenjamiB2425o.png" width="auto" />
                        <Card.Header>Benjamí B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Xènia Vivas/Jana Torras</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>    
            </Row>
            <Alert variant='warning'>
                <h1>Equip <Badge bg="danger">Pares delegats/delegades</Badge></h1>                
            </Alert>
            <Card>
                        <Card.Img variant="top" src="img/Delegats2425o.png" height="auto" width="auto"/>
                        <Card.Header>Delegats/Delegades</Card.Header>
                        <Card.Body>
                        </Card.Body>
            </Card>
        </div>
    )
}

export default Linksfcvb;