import { useState } from 'react';

export default function useAuth() {
  const getAuth = () => {
    var authString = localStorage.getItem('auth');
    if (authString === "" || authString === null) {
      authString = JSON.stringify({
        correct : false,
        id_user: 0,
        level: 0,
        name: ""
      });
    }
    const userAuth = JSON.parse(authString);
    return userAuth;
  };

  const [auth, setAuth] = useState(getAuth());

  const saveAuth = userAuth => {
    localStorage.setItem('auth', JSON.stringify(userAuth));
    console.log("guardem");
    console.log(userAuth);
    setAuth(userAuth);
  };

  return {
    setAuth: saveAuth,
    auth
  }
}