import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa'
import { CSSTransition } from "react-transition-group"
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import './NavbarVolei.scss';

const NavbarVolei = (props) => {

  const [isOpen, setIsOpen] = useState(false);  
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const isUserIn = props.isUserIn;
  const levelUser = props.level;
  const nameUser = props.name;

  const nodeRef = React.useRef(null)
  return (
    <div>
    {/* <CSSTransition nodeRef={nodeRef} in timeout={200} classNames="fade"> */}
    <Navbar className="App navbar" expand="md" light >
      <NavbarBrand className="mr-auto" href="/">
        <h1>Vic Vòlei Callís</h1>
        <a href="./Himne.mp3">        
          <div class="sound-div">
            <img src="/img/sound.jpg" alt="Himne" height="50">
            </img>
          </div>
      </a>
      </NavbarBrand>
      <NavbarToggler onClick={() => toggle()} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="links" navbar card fill pills>
            <NavItem>
              <NavLink href="/"><FaIcons.FaHome className="me-2" />Inici</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
              <FaIcons.FaVolleyballBall className="me-2"/>Equips
              </DropdownToggle>
              <DropdownMenu right>
                {/*<DropdownItem><NavLink href="/agenda"><FaIcons.FaDailymotion className="me-2" />Agenda</NavLink></DropdownItem>*/}
                <DropdownItem><NavLink href="/timetable"><FaIcons.FaTrain className="me-2" />Horaris d'Entrenament</NavLink></DropdownItem>
                <DropdownItem><NavLink href="/linksfcvb"><FaIcons.FaTrophy className="me-2" />Fotos i competicions</NavLink></DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
              <FaIcons.FaIdCard className="me-2"/>El club
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem><NavLink href="/junta"><FaIcons.FaInfo className="me-2" />la Junta</NavLink></DropdownItem>
                <DropdownItem><NavLink href="/voleialbums"><FaIcons.FaImages className="me-2" />Galeria de fotos</NavLink></DropdownItem>
                <DropdownItem><NavLink href="/contact"><FaIcons.FaMailBulk className="me-2" />Contacte</NavLink></DropdownItem>
                <DropdownItem><NavLink href="/denuncies"><FaIcons.FaChild className="me-2" />Bústia de denúncies</NavLink></DropdownItem>
                <DropdownItem><NavLink href="/documents"><FaIcons.FaDochub className="me-2" />Documents</NavLink></DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
            {isUserIn && (levelUser > 5) &&
                        <NavItem>
                        <NavLink href="/assistencia"><FaIcons.FaTrain className="me-2" />Assistència Entrenament</NavLink>
                      </NavItem>
            }
            {/*
            <NavItem>
              <NavLink href="/timetable"><FaIcons.FaTrain className="me-2" hidden={!isOpen}/>Horaris Entrenament</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/voleialbums"><FaIcons.FaImages className="me-2" hidden={!isOpen}/>Galeria</NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="/linksfcvb"><FaIcons.FaTrophy className="me-2" hidden={!isOpen}/>Equips</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contact"><FaIcons.FaMailBulk className="me-2" hidden={!isOpen}/>Contacte</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/denuncies"><FaIcons.FaMailchimp className="me-2" hidden={!isOpen}/>Bústia de denúncies</NavLink>
            </NavItem>            
            <NavItem>
              <NavLink href="/junta"><FaIcons.FaInfo className="me-2" hidden={!isOpen}/>la Junta</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/documents"><FaIcons.FaDochub className="me-2" hidden={!isOpen}/>Documents</NavLink>
            </NavItem>

          */}
            {isUserIn &&  (levelUser > 9) && <NavDropdown title="Equips gestió" id="basic-nav-dropdown">
              <NavDropdown.Item href="/players"><FaIcons.FaPeopleCarry className="me-2" />Jugadores</NavDropdown.Item>              
            </NavDropdown>}
            {isUserIn &&  (levelUser > 9) && <NavItem>
              <NavLink href="/blogcreate"><FaIcons.FaPaperPlane className="me-2"/>Nova entrada</NavLink>              
            </NavItem>}

            <NavItem>
              <NavLink href="https://fcvolei.cat" target="_blank" hidden={!isOpen}><FaIcons.FaExternalLinkAlt className="me-2" />federació catalana de voleibol</NavLink>
              <NavLink href="https://fcvolei.cat" target="_blank" hidden={isOpen}><img src="/img/logo-fcvb.png" alt="FCVB" height="20"></img></NavLink>
            </NavItem>
            {!isUserIn && <NavItem>
              <NavLink href="/login"><FaIcons.FaLockOpen className="me-2" />Login</NavLink>
            </NavItem>}
            {isUserIn && (levelUser > 9) && <NavDropdown title="Equips" id="basic-nav-dropdown">
              <NavDropdown.Item href="/categories">Categories</NavDropdown.Item>
              <NavDropdown.Item href="/jugadores">Jugadores</NavDropdown.Item>
              <NavDropdown.Item href="/entrenaments">Entrenaments</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/assistencia">Informe assistència</NavDropdown.Item>
          </NavDropdown>}
          {isUserIn && <NavItem>
              <NavLink href="/logout"><FaIcons.FaLock className="me-2" />Desconnectar ({nameUser})</NavLink>
            </NavItem>}
        </Nav>
       </Collapse>
    </Navbar>
    {/* </CSSTransition> */}
    </div>
  ); 

}

export default NavbarVolei;
