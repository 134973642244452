import React from 'react'
import { Button } from 'react-bootstrap'

const Agenda = (props) => {
    let title = 'Juvenil A';
    let info = null;
    let isFeed = false;

    const optionSenior = () => {
    }    
    const optionJuvenilA = () => {
        fetch('https://fcvolei.cat/apicompeticiones.php?0.24989454202645&slug=3a-divisio-juvenil-femenina-2324&fase=NaN')
        .then(response => response.json())
        .then(data => {info = data; console.log(info); isFeed = true;})        
    }  
    const optionJuvenilB = () => {
    }  
    const optionJuvenilC = () => {
    }  
    const optionJuvenilD = () => {
    }  
    const optionCadetA = () => {
    }  
    const optionCadetB = () => {
    }  
    const optionCadetC = () => {
    }  
    const optionCadetD = () => {
    }  
    const optionInfantilV = () => {
    }  
    const optionInfantilB = () => {
    }  
    const optionInfantilM = () => {
    }  
    const optionInfantilN = () => {
    }  
    const optionAlevi = () => {
    }  
    const optionBenjami = () => {
    }  
    const optionVeterans = () => {
    }  

        return (   
              
            <div>
                <h2> ASSISTÈNCIA D’ENTRENAMENT<br/>TEMPORADA 22-23 </h2>   
                <div className="interButtons">
                    <Button variant="outline-danger" onClick={optionSenior}>Sènior</Button><br/>
                    <Button variant="outline-danger" onClick={optionJuvenilA}>Juvenil A</Button><br/>
                    <Button variant="outline-danger" onClick={optionJuvenilB}>Juvenil B</Button><br/>
                    <Button variant="outline-danger" onClick={optionJuvenilC}>Juvenil C</Button><br/>
                    <Button variant="outline-danger" onClick={optionJuvenilD}>Juvenil D</Button><br/>
                    <Button variant="outline-danger" onClick={optionCadetA}>Cadet A</Button><br/>
                    <Button variant="outline-danger" onClick={optionCadetB}>Cadet B</Button><br/>
                    <Button variant="outline-danger" onClick={optionCadetC}>Cadet C</Button><br/>
                    <Button variant="outline-danger" onClick={optionCadetD}>Cadet D</Button><br/>
                    <Button variant="outline-danger" onClick={optionInfantilV}>Infantil Vermell</Button><br/>
                    <Button variant="outline-danger" onClick={optionInfantilB}>Inantil Blanc</Button><br/>
                    <Button variant="outline-danger" onClick={optionInfantilM}>Infantil Masculí</Button><br/>
                    <Button variant="outline-danger" onClick={optionInfantilN}>Inantil Negre</Button><br/>
                    <Button variant="outline-danger" onClick={optionAlevi}>Alevi</Button><br/>
                    <Button variant="outline-danger" onClick={optionBenjami}>Benjami</Button><br/>
                    <Button variant="outline-danger" onClick={optionVeterans}>Veterans</Button>
                </div>
                {isFeed &&
                    <div className="blog-list">
                    <p>{info.grupos}</p>
                    <br /><br />
                    <h1 className="text-center">{title}</h1>
                    <hr/>
                    {info.grupos.map(grupo => (
                        <div className="blog-preview" key={grupo.id}>
                            <p>{grupo}</p>
                        </div>
                    ))}
                    </div>
                }
            </div> 
            
            /*<div>
                <h2> ASSISTÈNCIA D’ENTRENAMENT<br/>TEMPORADA 22-23 </h2>   
                <div className="interButtons">
                    <Button variant="outline-danger" onClick={optionEquip(10)}></Button><br/>
                </div>
            </div> */
        )
}

export default Agenda