import * as React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom"

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

// Components
import NavbarVolei from './components/NavbarVolei';

/*import BlogCreate from './components/Blog/BlogCreate';
import BlogDetails from './components/Blog/BlogDetails';*/
import NotFound from './components/NotFound';
import useAuth from './components/useAuth';

// Pagines
import Home from './pages/Home';

import Login from './pages/Login/Login'
import Logout from './pages/Logout';

import Junta from './pages/Junta/Junta';
import Linksfcvb from './pages/Linksfcvb'
import Linksfcvb2122 from './pages/Linksfcvb2122';
import Linksfcvb2223 from './pages/Linksfcvb2223';
import Linksfcvb2324 from './pages/Linksfcvb2324';
import Entrenaments from './pages/Entrenaments/Entrenaments';
import VoleiGallery from './components/Gallery/voleiGallery';
import VoleiAlbums from './components/Gallery/voleiAlbums';
import Documents from './pages/Documents';
import Contact from './pages/Contact/Contact';
import Denuncies from './pages/Denuncies/Denuncies';
import Assistencia from './pages/Assistencia/Assistencia';
import Agenda from './pages/Agenda/Agenda';
/*
import PlayersTeam from './pages/Players/PlayersTeam';
import Calendari from './pages/Calendari/Calendari';
import Equips from './pages/Equips';
import Players from './pages/Players/Players';
import PlayersCreate from './pages/Players/PlayersCreate';
import PlayersUpdate from './pages/Players/PlayersUpdate';
*/
// Páginas internas

// Función del router
function App() {
  const { auth, setAuth } = useAuth();

  const setAuthIn = (props) => {
    if (props.correct === true) {
      setAuth(props);
    }
    else {
      setAuth("")
    }
  }

/*
        <NavbarVolei  isUserIn={auth.correct || false} level={auth.level} name={auth.name}/> */

return (
    <div className="content">
        <BrowserRouter>
          <NavbarVolei  isUserIn={auth.correct || false} level={auth.level} name={auth.name}/>
          <Routes>        
              <Route path="/" element={<Home />} />
              <Route exact path="/junta" element={<Junta />} />
              <Route exact path="/linksfcvb" element={<Linksfcvb />} />
              <Route exact path="/Linksfcvb2324" element={<Linksfcvb2324 />} />
              <Route exact path="/Linksfcvb2223" element={<Linksfcvb2223 />} />
              <Route exact path="/linksfcvb2122" element={<Linksfcvb2122 />} />
              <Route exact path="/timetable" element={<Entrenaments />} />

              <Route exact path="/login" element = { <Login setAuth = {setAuthIn} logged = {auth.correct} />} />
              <Route exact path="/logout" element = { <Logout setAuth = {setAuthIn} logged = {auth.correct} />} />

              <Route exact path="/voleialbums" element={<VoleiAlbums />} />
              <Route exact path="/voleigallery/:idalbum" element={<VoleiGallery />} />

              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/denuncies" element={<Denuncies/>} />
              <Route exact path="/documents" element={<Documents/>} />
              <Route exact path="/assistencia" element={<Assistencia/>} />
              <Route exact path="/agenda" element={<Agenda/>} />

{/*           

              <Route exact path="/equips" component={Equips} />

              <Route exact path="/players" component={Players} />
              <Route path='/playerupdate' component={PlayersUpdate} />  
              <Route path='/playercreate' component={PlayersCreate} />  
              <Route path='/playerteam' component={PlayersTeam} />  

              <Route path="/blogcreate"> <BlogCreate /></Route>
              <Route path="/blogs/:id"> <BlogDetails/></Route>
 */}
              <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
    </div>    
  );

  /*
  return (
    <Router>        
       <div className="App">
      <NavbarVolei  isUserIn={auth.correct || false} level={auth.level} name={auth.name}/>

       <div className="content">
         <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/linksfcvb" component={Linksfcvb} />
              <Route exact path="/linksfcvb2122" component={Linksfcvb2122} />
              <Route exact path="/linksfcvb2223" component={Linksfcvb2223} />
              <Route exact path="/equips" component={Equips} />

              <Route exact path="/players" component={Players} />
              <Route path='/playerupdate' component={PlayersUpdate} />  
              <Route path='/playercreate' component={PlayersCreate} />  
              <Route path='/playerteam' component={PlayersTeam} />  

              <Route exact path="/contact" component={Contact} />
              <Route exact path="/denuncies" component={Denuncies} />
              <Route exact path="/junta" component={Junta} />
              <Route exact path="/documents" component={Documents} />
              <Route exact path="/login" render = { () => <Login setAuth = {setAuthIn} logged = {auth.correct} />} />
              <Route exact path="/logout" render = { () => <Logout setAuth = {setAuthIn} logged = {auth.correct} />} />
              <Route exact path="/timetable" component={Entrenaments} />
              <Route exact path="/assistencia" component={Assistencia} />
              <Route exact path="/agenda" component={Agenda} />

              <Route path="/blogcreate"> <BlogCreate /></Route>
              <Route path="/blogs/:id"> <BlogDetails/></Route>

              <Route path="/voleialbums"> <VoleiAlbums /></Route>
              <Route path="/voleigallery/:idalbum" render={({ match }) => {
                    return <VoleiGallery idalbum={match.params.idalbum} />
                }} />


              <Route path="*"><NotFound/></Route>        
          </Switch>
        </div>
      </div>
    </Router>    
    );*/
}


export default App;
