import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const Assistencia = (props) => {
    let navigate = useNavigate();

    const optionSenior = () => {
        localStorage.setItem('id_mst_team', 10)
        navigate('/playerteam');
    }    
    const optionJuvenilA = () => {
        localStorage.setItem('id_mst_team', 20)
        navigate('/playerteam');
    }  
    const optionJuvenilB = () => {
        localStorage.setItem('id_mst_team', 21)
        navigate('/playerteam');
    }  
    const optionJuvenilC = () => {
        localStorage.setItem('id_mst_team', 22)
        navigate('/playerteam');
    }  
    const optionJuvenilD = () => {
        localStorage.setItem('id_mst_team', 23)
        navigate('/playerteam');
    }  
    const optionCadetA = () => {
        localStorage.setItem('id_mst_team', 30)
        navigate('/playerteam');
    }  
    const optionCadetB = () => {
        localStorage.setItem('id_mst_team', 31)
        navigate('/playerteam');
    }  
    const optionCadetC = () => {
        localStorage.setItem('id_mst_team', 32)
        navigate('/playerteam');
    }  
    const optionCadetD = () => {
        localStorage.setItem('id_mst_team', 33)
        navigate('/playerteam');
    }  
    const optionInfantilV = () => {
        localStorage.setItem('id_mst_team', 40)
        navigate('/playerteam');
    }  
    const optionInfantilB = () => {
        localStorage.setItem('id_mst_team', 41)
        navigate('/playerteam');
    }  
    const optionInfantilM = () => {
        localStorage.setItem('id_mst_team', 42)
        navigate('/playerteam');
    }  
    const optionInfantilN = () => {
        localStorage.setItem('id_mst_team', 43)
        navigate('/playerteam');
    }  
    const optionAlevi = () => {
        localStorage.setItem('id_mst_team', 50)
        navigate('/playerteam');
    }  
    const optionBenjami = () => {
        localStorage.setItem('id_mst_team', 60)
        navigate('/playerteam');
    }  
    const optionVeterans = () => {
        localStorage.setItem('id_mst_team', 1)
        navigate('/playerteam');
    }  

        return (   
              
            <div>
                <h2> ASSISTÈNCIA D’ENTRENAMENT<br/>TEMPORADA 22-23 </h2>   
                <div className="interButtons">
                    <Button variant="outline-danger" onClick={optionSenior}>Sènior</Button><br/>
                    <Button variant="outline-danger" onClick={optionJuvenilA}>Juvenil A</Button><br/>
                    <Button variant="outline-danger" onClick={optionJuvenilB}>Juvenil B</Button><br/>
                    <Button variant="outline-danger" onClick={optionJuvenilC}>Juvenil C</Button><br/>
                    <Button variant="outline-danger" onClick={optionJuvenilD}>Juvenil D</Button><br/>
                    <Button variant="outline-danger" onClick={optionCadetA}>Cadet A</Button><br/>
                    <Button variant="outline-danger" onClick={optionCadetB}>Cadet B</Button><br/>
                    <Button variant="outline-danger" onClick={optionCadetC}>Cadet C</Button><br/>
                    <Button variant="outline-danger" onClick={optionCadetD}>Cadet D</Button><br/>
                    <Button variant="outline-danger" onClick={optionInfantilV}>Infantil Vermell</Button><br/>
                    <Button variant="outline-danger" onClick={optionInfantilB}>Inantil Blanc</Button><br/>
                    <Button variant="outline-danger" onClick={optionInfantilM}>Infantil Masculí</Button><br/>
                    <Button variant="outline-danger" onClick={optionInfantilN}>Inantil Negre</Button><br/>
                    <Button variant="outline-danger" onClick={optionAlevi}>Alevi</Button><br/>
                    <Button variant="outline-danger" onClick={optionBenjami}>Benjami</Button><br/>
                    <Button variant="outline-danger" onClick={optionVeterans}>Veterans</Button>
                </div>
            </div> 
            
            /*<div>
                <h2> ASSISTÈNCIA D’ENTRENAMENT<br/>TEMPORADA 22-23 </h2>   
                <div className="interButtons">
                    <Button variant="outline-danger" onClick={optionEquip(10)}></Button><br/>
                </div>
            </div> */
        )
}

export default Assistencia