import React from 'react'
//<a href={permalink} target="_blank" rel="nofollow">
//<img 
//width='100%'
//height='auto'
//id={id} 
//src={media_url}  
///>
//</a>
const Feed = (props) => {
    const { id, media_type, media_url, permalink} = props.feed
    let post;
    let permalinkembed;

    permalinkembed = permalink + 'embed';
    switch (media_type) {
        case "VIDEO":
            post = (
                <div id = {id}>
                    <a href={permalink} target="_blank" rel="nofollow, noreferrer">
                <video
                    width='100%'
                    height='auto' 
                    src={media_url} 
                    type="video/mp4" 
                    controls playsinline>
                </video></a>
                </div>
            )
            break;
        case "CAROUSEL_ALBUM":
            post = (
                <div id = {id}>
                    <iframe title={id} src={permalinkembed} scrolling='no' frameBorder='0' width='auto' height='500'allowtransparency="true" ></iframe>
                </div>
            );
            break;
        default:
            post = (
                <div id = {id}>
                    <iframe title={id} src={permalinkembed} scrolling='no' frameBorder='0' width='auto' height='500'allowtransparency="true" ></iframe>
                </div>
            );
    }       

    return (
        <React.Fragment>
            {post}
        </React.Fragment>
    );
}

export default Feed;