import { Link } from 'react-router-dom';

const BlogList = ({blogs, title}) => {
    // console.log(blogs);
    return ( 
        <div>
            <div className="blog-list">
                <br /><br />
                <h1 className="text-center">{title}</h1>
                <hr/>
                {blogs.map(blog => (
                    <div className="blog-preview" key={blog.id_reg_bloc}>
                        <Link to={`/blogs/${blog.id_reg_bloc}`}>
                            <h2>{blog.title}</h2>
                            <h3>{blog.subtitle}</h3>
                            <p>Publicat el {blog.data_creacio}</p>
                        </Link>
                    </div>
                ))}
            </div>
            <div>
                Documents
            </div>
        </div>
     );
}
 
export default BlogList;