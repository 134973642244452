import { Card, Row, Col, Alert, Badge } from 'react-bootstrap';

const Linksfcvb2223 = (props) => {
    return (
        <div>
            <Alert variant='warning'>
                <h1>Equip <Badge bg="danger">Tècnic</Badge> Temporada 22-23</h1>                
                <div style={{textAlign: 'right'}}><a href="/Linksfcvb" class="btn btn-primary btn-lg active" role="button" aria-pressed="true">Temporada actual</a></div>
            </Alert>
            <Card>
                        <Card.Img variant="top" src="img/EquipTecnic.png" height="auto" width="auto"/>
                        <Card.Header>Equip Tècnic</Card.Header>
                        <Card.Body>
                            <Card.Text>Direcció esportiva: <br/><b>Irene Moncunill</b></Card.Text>
                            <Card.Text>Coordinació Competició: <br/><b>Andrea Vàzquez</b></Card.Text>
                            <Card.Text>Coordinació Formació: <br/><b>Aina Girart</b></Card.Text>
                        </Card.Body>
                    </Card>
            <Alert variant='info'>
                <h1>Us presentem els nostres <Badge bg="danger">Equips</Badge></h1>
            </Alert>
            <Row xs={1} md={2} lg={3} className="g-4">
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipSenior.png"  width="auto"/>
                        <Card.Header>Sènior</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Irene</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Quarta divisió sènior femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció A <Card.Link href="https://fcvolei.cat/voleibol/4A-DIVISIO-SENIOR-FEMENINA-2223" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>
                            <Card.Text>Seleccionar grup C <Card.Link href="https://fcvolei.cat/voleibol/4A-DIVISIO-SENIOR-FEMENINA-2223" target="_blank">Obrir Fase 1</Card.Link> </Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilA.png" width="auto" />
                        <Card.Header>Juvenil A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Andrea</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció F <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-JUVENIL-FEMENINA-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup N <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-JUVENIL-FEMENINA-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilB.png"  width="auto" />
                        <Card.Header>Juvenil B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Aina</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció B <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-JUVENIL-FEMENINA-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup N <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-JUVENIL-FEMENINA-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilC.png" width="auto" />
                        <Card.Header>Juvenil C</Card.Header>                        
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Clara</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferent juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació <Card.Link href="https://fcvolei.cat/voleibol/juvenil-femeni-preferent-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup C <Card.Link href="https://fcvolei.cat/voleibol/juvenil-femeni-preferent-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilD.png"  width="auto" />
                        <Card.Header>Juvenil D</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenador: <b>Matteo</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferemt juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació <Card.Link href="https://fcvolei.cat/voleibol/juvenil-femeni-preferent-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup C <Card.Link href="https://fcvolei.cat/voleibol/juvenil-femeni-preferent-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetA.png"   width="auto"/>
                        <Card.Header>Cadet A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Andrea/Queralt</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció F <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-CADET-FEMENINA-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup N <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-CADET-FEMENINA-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetB.png"  width="auto" />
                        <Card.Header>Cadet B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Aina</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció B <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-CADET-FEMENINA-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup N <Card.Link href="https://fcvolei.cat/voleibol/3A-DIVISIO-CADET-FEMENINA-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetC.png"   width="auto" height="auto"/>
                        <Card.Header>Cadet C</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Laia/Marta</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferent cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació A <Card.Link href="https://fcvolei.cat/voleibol/PREFERENT-CADET-FEMENI-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup D <Card.Link href="https://fcvolei.cat/voleibol/PREFERENT-CADET-FEMENI-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetD.png"  width="auto"/>
                        <Card.Header>Cadet D</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenador: <b>Nando</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>                
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilVermell.jpeg"  width="auto" />
                        <Card.Header>Infantil Vermell</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Ivet</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació OR A <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup I <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilBlanc.png" width="auto" />
                        <Card.Header>Infantil Blanc</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Judit/Aina</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació PLATA B <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup I <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilMasculi.png"  width="auto"/>
                        <Card.Header>Infantil Masculí</Card.Header>
                        <Card.Body>                       
                            <Card.Text>Entrenadora: <b>Alba</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió infantil masculina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació <Card.Link href="https://fcvolei.cat/voleibol/2a-infantil-masculina-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup C <Card.Link href="https://fcvolei.cat/voleibol/2a-infantil-masculina-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>   
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilNegre.png"   width="auto"/>
                        <Card.Header>Infantil Negre</Card.Header>
                            <Card.Text>Entrenadora: <b>Berta/Gabi</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferent infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació B <Card.Link href="https://fcvolei.cat/voleibol/preferent-infantil-femeni-2223" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup A <Card.Link href="https://fcvolei.cat/voleibol/preferent-infantil-femeni-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>                            
                        <Card.Body>
                           
                        </Card.Body>
                    </Card>
                </Col>   
                <Col>
                    <Card>
                        <Card.Img variant="top" src="img/EquipAleviMixt.png"  width="auto" />
                        <Card.Header>Aleví Mixt</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Eva</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Lliga Aleví Mixt</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar grup PRE C <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-2223" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Mixte Plata <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-2223" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>*/}
                            </Card.Footer>                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipBenjamines.png" width="auto" />
                        <Card.Header>Benjamines</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenador: <b>Nando</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipAmateur.png"  width="auto" />
                        <Card.Header>Veterans</Card.Header>
                        <Card.Body>
                        <Card.Body>
                            <Card.Text>Entrenador: <b>Matteo</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Amateur Mixtes</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar PLATA 2 <Card.Link href="https://fcvolei.cat/voleibol/LLIGA-AMATEUR-MIXT-2223/" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup G <Card.Link href="https://fcvolei.cat/voleibol/LLIGA-AMATEUR-MIXT-2223/" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Linksfcvb2223;