import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, CardHeader, CardImg, CardTitle } from 'reactstrap';

const VoleiAlbums = () => {

    const albums = [
        {albumName:'Presentació temporada 24-25', albumImg:'img/Club24-25.png', albumId:'4', albumroute:'/voleigallery/4'},
        {albumName:'Presentació temporada 23-24', albumImg:'img/Club23-24.png', albumId:'3', albumroute:'/voleigallery/3'},
        {albumName:'Presentació temporada 22-23', albumImg:'img/Club22-23.png', albumId:'2', albumroute:'/voleigallery/2'},
        {albumName:'Presentació temporada 21-22', albumImg:'img/Club21-22.png', albumId:'1', albumroute:'/voleigallery/1'},
        //{albumName:'segon', albumImg:'', albumId: '2', albumroute:'/voleigallery/2'}
    ]

    return (
        albums.map(item => {
            return (
                <div id={item.albumName} >
                    <Alert variant='warning'>
                        <h1>{item.albumName}</h1>
                    </Alert>
                    <Card>
                        <CardHeader>
                            <CardTitle></CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Link to={item.albumroute}  params={{ albumId: item.albumId }}><CardImg lt={item.albumName} src={item.albumImg} top width="100%" /></Link>
                        </CardBody>
                    </Card>
                </div>
            )
        })        
    )
}

export default VoleiAlbums;