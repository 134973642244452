import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Login.css";
import { redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import ResponsiveImage from "../../components/ResponsiveImage/ResponsiveImage";

async function validateUser(credentials) {
  var user = {
    correct : false,
    id_user: 0,
    level: 0,
    name: ""
  }
  if (credentials.email === "peptor@gmail.com" && credentials.password === "Peptor47") {
    user.correct = true;
    user.id_user = 1;
    user.level = 10;
    user.name = "Peptor";
  }
  if (credentials.email === "andrea@vicvoleicallis.cat" && credentials.password === "Andrea22_") {
    user.correct = true;
    user.id_user = 2;
    user.level = 6;
    user.name = "Andrea";
  }
  return user;
}

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const Swal = require('sweetalert2')

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const userAuth = await validateUser({
      email,
      password
    });
    console.log("token auth");
    console.log(userAuth);
    if (!userAuth.correct){
      Swal.fire({
        icon: 'error',
        title: 'Correu o contrasenya incorrectes',
        toast: true,
        position: 'top',
        timer: 3000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Identificació correcte',
        toast: true,
        position: 'top',
        timer: 3000,
        showConfirmButton: false
      });
    }
    props.setAuth(userAuth);
  }

  console.log('props.logged' );
  console.log(props.logged );

    if( props.logged === true){
      return (<redirect to="/home" />);
    }else{
    return (

        <div className="Login">
          <ResponsiveImage
                src="img/Logop.png"
                width={ 450 }F
                height={ 225 } />
          <Form onSubmit={handleSubmit}>
            <Form.Group size="lg" controlId="email">
              <Form.Label>Correu electrònic</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <br/>
            <Form.Group size="lg" controlId="password">
              <Form.Label>Contrasenya</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <br/>
            <Button block size="lg" type="submit" disabled={!validateForm()}>
              Entrar
            </Button>
          </Form>
        </div>
      );
    }
}

Login.propTypes = {
  setAuth: PropTypes.func.isRequired
};

export default Login;