import { Card, Row, Col, Alert, Badge } from 'react-bootstrap';

const Linksfcvb = (props) => {
    return (
        <div>
            <Alert variant='warning'>
                <h1>Equip <Badge bg="danger">Tècnic</Badge> Temporada 23-24</h1>                
                <div style={{textAlign: 'right'}}><a href="/Linksfcvb" class="btn btn-primary btn-lg active" role="button" aria-pressed="true">Temporada actual</a></div>
            </Alert>
            <Card>
                        <Card.Img variant="top" src="img/EquipTecnic2324.png" height="auto" width="auto"/>
                        <Card.Header>Equip Tècnic</Card.Header>
                        <Card.Body>
                            <Card.Text>Direcció esportiva: <br/><b>Irene Moncunill</b></Card.Text>
                            <Card.Text>Coordinació Competició: <br/><b>Andrea Vázquez</b></Card.Text>
                            <Card.Text>Coordinació Formació: <br/><b>Aina Girart</b></Card.Text>
                        </Card.Body>
                    </Card>
            <Alert variant='info'>
                <h1>Us presentem els nostres <Badge bg="danger">Equips</Badge></h1>
            </Alert>
            <Row xs={1} md={2} lg={3} className="g-4">
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipSenior2324o2.png"  width="auto"/>
                        <Card.Header>Sènior</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Helen Solaki</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió sènior femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció A <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-senior-femenina-2324" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>
                            <Card.Text>Seleccionar grup D <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-senior-femenina-2324" target="_blank">Obrir Fase 1</Card.Link> </Card.Text>                            
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJunior2324o.png"  width="auto"/>
                        <Card.Header>Júnior</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Andrea Vázquez/Marina Berga</b></Card.Text>
                            <Card.Subtitle className="mb-2 te xt-muted">Segona divisió júnior femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació A <Card.Link href="https://fcvolei.cat/voleibol/2a-junior-femenina-2324" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>
                            <Card.Text>Seleccionar grup A <Card.Link href="https://fcvolei.cat/voleibol/2a-junior-femenina-2324/" target="_blank">Obrir Fase 1</Card.Link> </Card.Text>
                            
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenila2324o.png" width="auto" />
                        <Card.Header>Juvenil A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Berta Altimiras</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció F <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup L <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>                        
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilb2324o.png"  width="auto" />
                        <Card.Header>Juvenil B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Laia Bassas/Erola Serrat</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció G <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup K <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenilc2324o.png" width="auto" />
                        <Card.Header>Juvenil C</Card.Header>                        
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Clara Clusella/Paula Clusella</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació B <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup E <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-juvenil-femenina-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
    
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipJuvenild2324o.png"  width="auto" />
                        <Card.Header>Juvenil D</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenador/a: <b>Matteo Burlotti</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferemt juvenil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació <Card.Link href="https://fcvolei.cat/voleibol/juvenil-femeni-preferent-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup B <Card.Link href="https://fcvolei.cat/voleibol/juvenil-femeni-preferent-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadeta2324o.png"   width="auto"/>
                        <Card.Header>Cadet A</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Berta Altimiras</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció F <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup L <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
            
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetb2324o.png"  width="auto" />
                        <Card.Header>Cadet B</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Laia Bassas/Erola Serra</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar promoció G <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup K <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
        
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetc2324o.png"   width="auto" height="auto"/>
                        <Card.Header>Cadet C</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Clara Clusella/Marta Torra</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Tercera divisió cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació B <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup E <Card.Link href="https://fcvolei.cat/voleibol/3a-divisio-cadet-femenina-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
    
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetd2324o.png"   width="auto" height="auto"/>
                        <Card.Header>Cadet D</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Queralt Bonet/Arianna </b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferent cadet femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació OR B <Card.Link href="https://fcvolei.cat/voleibol/preferent-cadet-femeni-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup E <Card.Link href="https://fcvolei.cat/voleibol/preferent-cadet-femeni-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
    
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipCadetm2324o.png"  width="auto"/>
                        <Card.Header>Cadet Masculí</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora/r: <b>Irene Moncunill/Manuel Isidro Medina</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió cadet masculina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar campionat C <Card.Link href="https://fcvolei.cat/voleibol/2a-cadet-masculina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup D <Card.Link href="https://fcvolei.cat/voleibol/2a-cadet-masculina-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
    
                            </Card.Footer>                            
                        </Card.Body>
                    </Card>
                </Col>                
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilv2324o.png"  width="auto" />
                        <Card.Header>Infantil Vermell</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadora: <b>Ivet Malagón/Iona Tuneu</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Segona divisió infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació OR A <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup N <Card.Link href="https://fcvolei.cat/voleibol/2a-divisio-infantil-femenina-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipInfantilb2324o.png" width="auto" />
                        <Card.Header>Infantil Blanc</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Anouk Castaño/Mireia Alcaraz</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Preferent infantil femenina</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació A <Card.Link href="https://fcvolei.cat/voleibol/preferent-infantil-femeni-2324" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup D <Card.Link href="https://fcvolei.cat/voleibol/preferent-infantil-femeni-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src="img/EquipAleviVermell2324o.png"  width="auto" />
                        <Card.Header>Aleví Vermell</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Aran Roig</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Lliga Aleví Mixt</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar Campionat catalynya 3x3 campionat A <Card.Link href="https://fcvolei.cat/voleibol/campionat-de-catalunya-alevi-3x3-2324/" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>
                            <Card.Text>Seleccionar grup 11 <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-2324" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            
                            </Card.Footer>                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src="img/EquipAleviBlanc2324o.png"  width="auto" />
                        <Card.Header>Aleví Blanc</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Jordina Pladevall</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Lliga Aleví Mixt</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar Campionat 4x4 femení campionat A <Card.Link href="https://fcvolei.cat/voleibol/campionat-de-catalunya-alevi-femeni-2324/" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>
                            <Card.Text>Seleccionar grup 8 <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-2324/" target="_blank">Obrir Fase 1</Card.Link></Card.Text>                            
                            </Card.Footer>                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Img variant="top" src="img/EquipAleviNegre2324o.png"  width="auto" />
                        <Card.Header>Aleví Negre</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenadores: <b>Aina Comes/Jana Torras</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Lliga Aleví Mixt</Card.Subtitle>
                            <Card.Footer>
                            {/*<Card.Text>Seleccionar grup 8 <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-2324/" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            <Card.Text>Seleccionar Mixte Plata <Card.Link href="https://fcvolei.cat/voleibol/lliga-alevi-mixt-2223" target="_blank">Obrir Fase 2</Card.Link> </Card.Text>
                            */}
                            </Card.Footer>                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipBenjamines2324o.png" width="auto" />
                        <Card.Header>Benjamines</Card.Header>
                        <Card.Body>
                            <Card.Text>Entrenador/a: <b>Nando/Bruna</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Img variant="top" src="img/EquipMaster2324o.png"  width="auto" />
                        <Card.Header>Veterans</Card.Header>
                        <Card.Body>
                        <Card.Body>
                            <Card.Text>Entrenador: <b>Matteo</b></Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Amateur Mixtes</Card.Subtitle>
                            <Card.Footer>
                            <Card.Text>Seleccionar classificació bonze A <Card.Link href="https://fcvolei.cat/voleibol/LLIGA-AMATEUR-MIXT-2324/" target="_blank">Obrir Fase 2</Card.Link></Card.Text>
                            <Card.Text>Seleccionar grup I <Card.Link href="https://fcvolei.cat/voleibol/LLIGA-AMATEUR-MIXT-2223/" target="_blank">Obrir Fase 1</Card.Link></Card.Text>
                            </Card.Footer>
                        </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Linksfcvb;