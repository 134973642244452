import React  from "react";
import { redirect } from "react-router-dom";

const Logout = (props) => {
    const Swal = require('sweetalert2')
console.log(props);

    if( props.logged === true){
        props.setAuth("false");
        Swal.fire({
            icon: 'info',
            title: 'Fins un altre!!',
            toast: true,
            position: 'top',
            timer: 3000,
            showConfirmButton: false
          });
        return (<redirect to="/home" />);
    } else {
        return (<redirect to="/login" />);
    }
}


export default Logout;