import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Feed from './Feed'

import './InstaFeeds.css'

const InstaFeeds = (props) => {
    const { token, limit} = props
    const [feeds, setFeedsData] = useState([])
    //use useRef to store the latest value of the prop without firing the effect

    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost () {
          try{
            axios
                .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,permalink&limit=${limit}&access_token=${token}`)
                .then((resp) => {
                    setFeedsData(resp.data.data)
                })
          } catch (err) {
              console.log('error', err)
          }
        }
        
        // manually call the fecth function 
        fetchInstagramPost();
  
        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort(); 
        };
    }, [limit, token])

    return (
        <div className="container">
            {feeds.map((feed, index) => (
                <div key={index} className='container'>
                    <p>{feed.caption} </p>
                <Feed key={feed.id} feed={feed} />
                </div>
            ))}
        </div>
    );
}

export default InstaFeeds;