// import reportWebVitals from './reportWebVitals';
// import 'semantic-ui-css/semantic.min.css'

import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
